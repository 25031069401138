<template>

  <div class="container_wrapper" id="sbLeft">

    <div class="container_header_detail"><i class="fab fa-buromobelexperte fas-left"></i><translate>DEVICE-SET</translate></div>

    <div class="container_body sb-left">

      <div class="sysinfoHead-left-pro">
        <i class="fas fa-question-circle fas-small fas-left"></i><translate>INFO</translate>
      </div>

      <div class="sysinfoBody">
        <div class="truncateLeft"><translate>NAME</translate> ....................................................................................................................</div>
        <div class="sysinfoWrapper-left color09">
          <span style="display:flex;"><b>{{ (farm ? farm.name : $gettext('\'N/A\'')) }}</b></span>
        </div>
      </div>

      <div class="sysinfoHead-left-pro">
        <i class="fas fa-server fas-small fas-left"></i><translate>MINERS</translate>
      </div>

      <div class="sysinfoBody">
        <div class="truncateLeft"><translate>ONLINE</translate> ..................................................................................................................</div>
        <div v-if='latestStatMessage && isOnline && latestStatMessage.miner_count_online' :class="'sysinfoWrapper-left ' + onlinecountIndication"><b>{{ latestStatMessage.miner_count_online }}</b><i class="fas fa-search fas-right deviceListMagnifier trans" @click="jumpToPage('ONLINE')"></i></div>
        <div v-else class="sysinfoWrapper-left darkred"><b>n/a</b><i class="fas fa-search fas-right deviceListMagnifier trans" @click="jumpToPage('ONLINE')"></i></div>
      </div>

      <div class="sysinfoBody">
        <div class="truncateLeft"><translate>OFFLINE</translate> ..................................................................................................................</div>
        <div v-if='latestStatMessage && isOnline && latestStatMessage.miner_count_total && latestStatMessage.miner_count_online' :class="'sysinfoWrapper-left '"><b>{{ latestStatMessage.miner_count_total - latestStatMessage.miner_count_online }}</b><i class="fas fa-search fas-right deviceListMagnifier trans" @click="jumpToPage('OFFLINE')"></i></div>
        <div v-else class="sysinfoWrapper-left"><b>n/a</b><i class="fas fa-search fas-right deviceListMagnifier trans" @click="jumpToPage('OFFLINE')"></i></div>
      </div>

      <div class="sysinfoBody">
        <div class="truncateLeft"><translate>FAULTY</translate> ..................................................................................................................</div>
        <div v-if='latestStatMessage && isOnline && latestStatMessage.miner_count_faulty' :class="'sysinfoWrapper-left '"><b>{{ latestStatMessage.miner_count_faulty }}</b><i class="fas fa-search fas-right deviceListMagnifier trans" @click="jumpToPage('FAULTY')"></i></div>
        <div v-else class="sysinfoWrapper-left"><b>n/a</b><i class="fas fa-search fas-right deviceListMagnifier trans" @click="jumpToPage('FAULTY')"></i></div>
      </div>

      <div class="sysinfoBody">
        <div class="truncateLeft"><translate>TOTAL</translate> ..................................................................................................................</div>
        <div v-if='latestStatMessage && latestStatMessage.miner_count_total' :class="'sysinfoWrapper-left '"><b>{{ latestStatMessage.miner_count_total }}</b><i class="fas fa-search fas-right deviceListMagnifier trans" @click="jumpToPage('ALL')"></i></div>
        <div v-else class="sysinfoWrapper-left"><b>n/a</b><i class="fas fa-search fas-right deviceListMagnifier trans" @click="jumpToPage('ALL')"></i></div>
      </div>

      <div class="sysinfoHead-left-pro" v-if="latestStatMessage">
        <i class="fas fa-bolt fas-small fas-left"></i><translate>CONSUMPTION</translate>
      </div>

      <div class="sysinfoBody" v-if="latestStatMessage">
        <div class="truncateLeft"><translate>POWERDRAW ({{ getPowerConsumptionUnit() }})</translate> ..................................................................................................................</div>
        <div v-if='latestStatMessage.power_consumption && isOnline' :class="'sysinfoWrapper-left '"><b>{{ getPowerConsumption() }}</b></div>
        <div v-else class="sysinfoWrapper-left"><b>n/a</b></div>
      </div>

      <div class="sysinfoBody" v-if="latestStatMessage">
        <div class="truncateLeft"><translate>EFFICIENCY (J/TH)</translate> ..................................................................................................................</div>
        <div v-if='latestStatMessage.power_consumption && isOnline' :class="'sysinfoWrapper-left '"><b>{{ getEfficiency() }}</b></div>
        <div v-else class="sysinfoWrapper-left"><b>n/a</b></div>
      </div>

      <div class="sysinfoHead-left-pro">
        <i class="fas fa-wallet fas-small fas-left"></i><translate>EXPANSES</translate>
      </div>

      <div class="sysinfoBody">
        <div class="truncateLeft"><translate>COST</translate> ({{ farm.currency | currencySymbol }}/h) ..................................................................................................................</div>
        <div v-if='latestStatMessage.power_consumption && farm.price_kwh' :class="'sysinfoWrapper-left '"><b>{{ getCost('h', farm.currency) }}</b></div>
        <div v-else class="sysinfoWrapper-left"><b>n/a</b></div>
      </div>

      <div class="sysinfoBody">
        <div class="truncateLeft"><translate>COST</translate> ({{ farm.currency | currencySymbol }}/d) ..................................................................................................................</div>
        <div v-if='latestStatMessage.power_consumption && farm.price_kwh' :class="'sysinfoWrapper-left '"><b>{{ getCost('d', farm.currency) }}</b></div>
        <div v-else class="sysinfoWrapper-left"><b>n/a</b></div>
      </div>

      <div class="sysinfoBody" style="margin-bottom: 0px;">
        <div class="truncateLeft"><translate>COST</translate> ({{ farm.currency | currencySymbol }}/m) ..................................................................................................................</div>
        <div v-if='latestStatMessage.power_consumption && farm.price_kwh' :class="'sysinfoWrapper-left '"><b>{{ getCost('m', farm.currency) }}</b></div>
        <div v-else class="sysinfoWrapper-left"><b>n/a</b></div>
      </div>

      <div class="sysinfoHead-left-pro" v-if='product_mode == "pro"'>
        <i class="fas fa-heartbeat fas-small fas-left"></i><translate>SYSTEM</translate>
      </div>

      <div class="sysinfoBody" v-if='product_mode == "pro"'>
        <div class="truncateLeft"><translate>UPTIME</translate> ......................................................................................................................</div>
        <div class="sysinfoWrapper-left">7d14h</div>
      </div>

      <div class="sysinfoBody" v-if='product_mode == "pro"'>
        <div class="truncateLeft"><translate>DISK (USED)</translate> ......................................................................................................................</div>
        <div class="sysinfoWrapper-left yellow">68%</div>
      </div>

      <div class="sysinfoBody" v-if='product_mode == "pro"'>
        <div class="truncateLeft"><translate>CPU TEMP</translate> ......................................................................................................................</div>
        <div class="sysinfoWrapper-left green">68°</div>
      </div>

      <div class="sysinfoBody" v-if='product_mode == "pro"'>
        <div class="truncateLeft"><translate>ENV TEMP</translate> ......................................................................................................................</div>
        <div class="sysinfoWrapper-left green">28°</div>
      </div>

      <div class="sysinfoHead-left-pro" v-if='product_mode == "pro"'>
        <i class="fas fa-network-wired fas-small fas-left"></i><translate>NETWORK</translate>
      </div>

      <div class="sysinfoBody" v-if='product_mode == "pro"'>
        <div class="truncateLeft"><translate>LAN</translate> ......................................................................................................................</div>
        <div class="sysinfoWrapper-left green">
          <span class="green"><i class="fas fa-check-circle fas-right" style="padding-left:0px;font-size: 0.85em;"></i></span>
        </div>
      </div>

      <div class="sysinfoBody" v-if='product_mode == "pro"'>
        <div class="truncateLeft"><translate>WAN</translate> ......................................................................................................................</div>
        <div class="sysinfoWrapper-left green">
          <span class="green"><i class="fas fa-check-circle fas-right" style="padding-left:0px;font-size: 0.85em;"></i></span>
        </div>
      </div>

      <div class="sysinfoHead-left-pro">
        <i class="fas fa-wrench fas-small fas-left"></i><translate>MORE</translate>
      </div>

      <div class="sysinfoBody" style="padding-top:3px; display: block; margin-bottom: -10px;">
        <button type="button" class="btn btn-secondary" style="margin-bottom: 0px; margin-top: 0px;" @click="$refs.FarmSettings.$refs.editFarm.openModal();" :title="$gettext('Edit device-set configuration.')"><i class="fas fa-cogs fas-left"></i><translate>SETTINGS</translate></button>
        <button type="button" class="btn btn-secondary" style="margin-bottom: 0px; margin-top: 5px;" @click="$refs.DeviceImporter.$refs.addDevicesModal.openModal();" :title="$gettext('Edit device-set configuration.')"><i class="fas fa-plus-circle fas-left"></i><translate>ADD DEVICES</translate></button>
      </div>

    </div>

    <!-- COMPONENT : FARM SETTiNGS -->

    <FarmSettings v-if="farm" v-bind:farm="farm" v-bind:worker_mode="farm.worker_mode" ref="FarmSettings" />

    <!-- MODAL : COPY FARM-ID -->

    <Modal v-if='farm' ref="copyDeviceSetIdModal">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-fingerprint fas-left"></i> <translate>YOUR DEVICE-SET ID</translate></h5>
      </template>
      <template v-slot:body>
        <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
          <div><i class="grow fas fa-check fas-small fas-left green" style="font-size: 4em;"></i></div>
          <div class="pulsate green" style="letter-spacing: 1px; font-size: 1em; margin-top: 0px; font-weight: bold;"> {{ farm.deviceset_id }}</div>
          <div class="pulsate" style="margin-top:12px;" v-translate>The <b>Device-Set ID</b> has been copied to the clipboard!</div>
        </div>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='btn btn-primary' @click="$refs.copyDeviceSetIdModal.closeModal();"><i class="fas fas-xsBtn fa-times fas-left"></i><translate>CLOSE</translate></button>
        </div>
      </template>
    </Modal>

    <!-- COMPONENT : ADD DEVICES TO DEVICE-SET -->

    <DeviceImporter ref="DeviceImporter" v-if="product_mode === 'pro' && farm" v-bind:farm="farm"/>

    <DeviceImporterCloud ref="DeviceImporter" v-if="product_mode === 'cloud' && farm" v-bind:farm="farm"/>

  </div>

</template>

<script>
import Modal from '@/components/widgets/Modal.vue'
import FarmSettings from '@/components/widgets/FarmSettings.vue'
import DeviceImporter from '@/components/widgets/DeviceImporter.vue'
import DeviceImporterCloud from '@/components/widgets/DeviceImporterCloud.vue'
import { PRODUCT_MODE } from '@/common/config'

export default {
  name: 'SidebarLeft',
  props: ['farm', 'latestStatMessage'],
  data () {
    return {
      product_mode: PRODUCT_MODE,
      timeNow: Math.floor(Date.now() / 1000)
    }
  },
  components: { Modal, FarmSettings, DeviceImporter, DeviceImporterCloud },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1).toUpperCase()
    },
    currencySymbol: function (value) {
      if (value === 'BTC') {
        return '₿'
      } else if (value === 'EUR') {
        return '€'
      } else {
        return '$'
      }
    }
  },
  methods: {
    jumpToPage (f) {
      this.$router.push({ name: 'detail_devices', query: { filter: f } })
    },
    getEfficiency () {
      if (this.latestStatMessage.hashrate_total > 0) {
        if (this.latestStatMessage.hashrate_unit === 'ZH') {
          return ((this.latestStatMessage.power_consumption / this.latestStatMessage.hashrate_total) / 1000000000).toFixed(0)
        } else if (this.latestStatMessage.hashrate_unit === 'EH') {
          return ((this.latestStatMessage.power_consumption / this.latestStatMessage.hashrate_total) / 1000000).toFixed(0)
        } else if (this.latestStatMessage.hashrate_unit === 'PH') {
          return ((this.latestStatMessage.power_consumption / this.latestStatMessage.hashrate_total) / 1000).toFixed(0)
        } else {
          return (this.latestStatMessage.power_consumption / this.latestStatMessage.hashrate_total).toFixed(0)
        }
      } else {
        return 'n/a'
      }
    },
    getPowerConsumption () {
      if (this.latestStatMessage.power_consumption) {
        if ((this.latestStatMessage.power_consumption / 1000) >= 1000) {
          return (this.latestStatMessage.power_consumption / 1000000).toFixed(1)
        } else {
          return (this.latestStatMessage.power_consumption / 1000).toFixed(1)
        }
      } else {
        return 'n/a'
      }
    },
    getPowerConsumptionUnit () {
      if (this.latestStatMessage.power_consumption) {
        if ((this.latestStatMessage.power_consumption / 1000) >= 1000) {
          return 'MW'
        } else {
          return 'kW'
        }
      } else {
        return 'kW'
      }
    },
    getProfitPerW () {
      return 'n/a'
    },
    getCost (period, value) {
      if (period === 'h') {
        if (value === 'BTC') {
          return ((this.latestStatMessage.power_consumption * this.farm.price_kwh) / 1000).toFixed(8)
        } else {
          return ((this.latestStatMessage.power_consumption * this.farm.price_kwh) / 1000).toFixed(2)
        }
      } else if (period === 'd') {
        if (value === 'BTC') {
          return (((this.latestStatMessage.power_consumption * this.farm.price_kwh) / 1000) * 24).toFixed(8)
        } else {
          return (((this.latestStatMessage.power_consumption * this.farm.price_kwh) / 1000) * 24).toFixed(2)
        }
      } else if (period === 'm') {
        if (value === 'BTC') {
          return (((this.latestStatMessage.power_consumption * this.farm.price_kwh) / 1000) * 24 * 30.5).toFixed(8)
        } else {
          return (((this.latestStatMessage.power_consumption * this.farm.price_kwh) / 1000) * 24 * 30.5).toFixed(2)
        }
      }
    },
    async copyDeviceSetId () {
      await navigator.clipboard.writeText(this.farm.deviceset_id)
    }
  },
  computed: {
    isOnline () {
      return this.latestStatMessage && this.latestStatMessage.miner_count_online > 0 && (this.latestStatMessage.created > (this.timeNow - 180))
    },
    onlinecountIndication () {
      const onlineCount = (this.latestStatMessage ? this.latestStatMessage.miner_count_online : 0)
      const totalCount = (this.latestStatMessage ? this.latestStatMessage.miner_count_total : 0)
      let result = null
      switch (true) {
        case onlineCount === 0:
          result = 'red pulsate'
          break
        case (onlineCount < totalCount):
          result = 'yellow'
          break
        case onlineCount === totalCount:
          result = 'green'
          break
      }
      return result
    }
  }
}
</script>
