<template>

  <section class="container_wrapper" id="sbRight" style="position: relative; height:100%!important;">

    <div class="container_header_detail">
      <span v-if='product_mode === "pro"' class="swapSlides trans" style="float:left; padding: 5px 0 0 10px;" @click="swapSlides()"><i class="fas fa-caret-square-left fas-small fas-left"></i></span>
      <i class="fas fa-chart-line fas-right" style="padding-left:0px;"></i> <translate>STATS</translate>
      <span v-if='product_mode === "pro"' class="swapSlides trans" style="float:right; padding: 5px 10px 0 0;" @click="swapSlides()"><i class="fas fa-caret-square-right fas-small fas-right"></i></span>
    </div>

    <!-- CLOUD -->

    <div class="container_body sb-right-farmstats" v-if='product_mode === "cloud"'>

      <div class="container-fluid">

        <div class="row row-detail">

          <div class="col-sm-12 col-lg-12 col-12 fp-bar-head">
            <div class="cInfoHead trans" :title="$gettext('Current pool status indicator.')">
              <i class="fas fa-bezier-curve fas-large fas-left"></i><span v-if='!isMobile()'><translate>POOL STATUS</translate></span>
            </div>
            <div v-if='latestStatMessage' :class="'cInfoBodyDetail ' + poolIndication">
              <span v-if='latestStatMessage.pool_status === 1 && isOnline' class="green" :title="$gettext('All your miners are properly connected to a pool.')"><i class="fas fa-check-circle fas-right" style="padding-left:0px;"></i></span>
              <span v-else class="red" :title="$gettext('One or more devices have issues with their connection to the pool. This is to be expected during restart of miners for instance (after configuration changes).')"><i class="fas fa-exclamation-triangle fas-right" style="padding-left:0px;"></i></span>
            </div>
          </div>

          <div class="col-sm-12 col-lg-12 col-12 fp-bar-head">
            <div class="cInfoHead trans" :title="$gettext('Amount of devices currently online and total devices.')">
              <i class="fas fa-server fas-large fas-left"></i><span v-if='!isMobile()'><translate>ASICS ONLINE</translate></span>
            </div>
            <div v-if='!isOnline' class="cInfoBodyDetail red pulsate">
              n/a
            </div>
            <div v-else :class="'cInfoBodyDetail ' + onlinecountIndication">
              {{ latestStatMessage.miner_count_online }}/{{ latestStatMessage.miner_count_total }}
            </div>
          </div>

          <div class="col-sm-12 col-lg-12 col-12 fp-bar-head">
            <div class="cInfoHead trans" :title="$gettext('Average temperature of all miners combined.')">
              <i class="fas fa-thermometer-quarter fas-small fas-large fas-left"></i><span v-if='!isMobile()'><translate>TEMPERATURE</translate></span>
            </div>
            <div v-if='latestStatMessage && isOnline' :class="'cInfoBodyDetail ' + temperatureIndication">
              {{ (latestStatMessage.temperature_avg, latestStatMessage.temperature_avg > 0 ? latestStatMessage.temperature_avg.toFixed(0) + '°C' : 'n/a') }}
            </div>
            <div v-else class="cInfoBodyDetail red pulsate">
              n/a
            </div>
          </div>

          <div class="col-sm-12 col-lg-12 col-12 fp-bar-head">
            <div class="cInfoHead trans" :title="$gettext('Average fan speed of all miners combined.')">
              <i class="fas fa-fan fas-small fas-large fas-left"></i><span v-if='!isMobile()'><translate>FANSPEED</translate></span>
            </div>
            <div v-if='latestStatMessage && isOnline' :class="'cInfoBodyDetail ' + fanspeedIndication">
              {{ (latestStatMessage.fanspeed_avg, latestStatMessage.fanspeed_avg > 0 ? (latestStatMessage.fanspeed_avg > 100 ? '100%' : latestStatMessage.fanspeed_avg.toFixed(0) + '%') : 'n/a') }}
            </div>
            <div v-else class="cInfoBodyDetail red pulsate">
              n/a
            </div>
          </div>

          <div class="col-sm-12 col-lg-12 col-12 fp-bar-head">
            <div class="cInfoHead trans" :title="$gettext('The total hashrate of all miners combined.')">
              <i class="fas fa-chart-bar fas-small fas-large fas-left"></i><span v-if='!isMobile()'><translate>HASHRATE</translate></span>
            </div>
            <div v-if='latestStatMessage && isOnline' class="cInfoBodyDetail hrReported">
              {{ hashrateTotal }} {{ hashrateUnit }}/s
            </div>
            <div v-else class="cInfoBodyDetail red pulsate">
              n/a
            </div>
          </div>

        </div>

      </div>

    </div>

    <!-- PRO / POOL -->

    <div v-else style="height:100%;">

      <transition-group name="component-fade">

        <!-- DATA 2 - FANCY POOL STATS -->

        <div key="poolstats" v-if="show" class="container_body sb-right">

          <div class="sysinfoHead-right-pro" style="border-bottom: none!important; color: rgb(101 101 101); margin-bottom: -13px; margin-top: -1px; font-size: 0.7em!important;">
            Mining on...
          </div>

          <div class="sysinfoBody" style="margin-bottom:10px; margin-top: 5px;display:block;">
            <span class="sysinfoWrapper-right"><a class="genLink" target="_blank" title="Visit." :href="'https://' + pool_api + '.com'"><img src="@/assets/img_ext/pools/antpool.png" :title="'Currently mining on: ' + pool_api" style="border-radius: 0px!important;"></a></span>
          </div>

          <div class="sysinfoHead-right-pro">
            STATUS<i class="fas fa-bezier-curve fas-small fas-right"></i>
          </div>

          <div class="sysinfoBody">
            <div class="truncateLeft">CONNECTION ......................................................................................................................</div>
            <div class="sysinfoWrapper-right">
              <div v-if='latestStatMessage' :class="poolIndication">
                <span v-if='latestStatMessage.pool_status === 1 && isOnline' class="green" :title="$gettext('All your miners are properly connected to a pool.')"><i class="fas fa-check-circle fas-right" style="padding-left:0px;font-size: 0.85em;"></i></span>
                <span v-else class="red" :title="$gettext('One or more devices have issues with their connection to the pool. This is to be expected during restart of miners for instance (after configuration changes).')"><i class="fas fa-exclamation-triangle fas-right" style="padding-left:0px;font-size: 0.85em;"></i></span>
              </div>
            </div>
          </div>

          <div class="sysinfoBody">
            <div class="truncateLeft">WORKER | ASICS ......................................................................................................................</div>
            <div :class="'sysinfoWrapper-right ' + workercountIndication"><b>{{ latestStatMessage && isOnline && latestStatMessage.miner_count_online ? (latestStatMessage.pool_worker && latestStatMessage.pool_worker > 0 ? latestStatMessage.pool_worker : 0) + '|'+ latestStatMessage.miner_count_online : 'n/a' }}</b></div>
          </div>

          <div class="sysinfoHead-right-pro">
            SHARES<i class="fas fa-file-medical-alt fas-small fas-right"></i>
          </div>

          <div class="sysinfoBody">
            <div class="truncateLeft">VALID ......................................................................................................................</div>
            <div class="sysinfoWrapper-right"><b>{{ latestStatMessage && latestStatMessage.pool_shares_accepted ? latestStatMessage.pool_shares_accepted : 'n/a' }}</b></div>
          </div>

          <div class="sysinfoBody">
            <div class="truncateLeft">INVALID ......................................................................................................................</div>
            <div class="sysinfoWrapper-right"><b>{{ latestStatMessage && latestStatMessage.pool_shares_invalid ? latestStatMessage.pool_shares_invalid : 'n/a' }}</b></div>
          </div>

          <div class="sysinfoBody">
            <div class="truncateLeft">STALE ......................................................................................................................</div>
            <div class="sysinfoWrapper-right"><b>{{ latestStatMessage && latestStatMessage.pool_shares_stale ? latestStatMessage.pool_shares_stale : 'n/a' }}</b></div>
          </div>

          <div class="sysinfoBody">
            <div class="truncateLeft">REJECTED ......................................................................................................................</div>
            <div class="sysinfoWrapper-right"><b>{{ latestStatMessage && latestStatMessage.pool_shares_rejected ? latestStatMessage.pool_shares_rejected : 'n/a' }}</b></div>
          </div>

          <div class="sysinfoHead-right-pro">
            HASHRATES<i class="fas fa-chart-bar fas-small fas-right"></i>
          </div>

          <div class="sysinfoBody">
            <div class="truncateLeft">REALTIME ......................................................................................................................</div>
            <div class="sysinfoWrapper-right color06"><b>{{ latestStatMessage && latestStatMessage.hashrate_total ? latestStatMessage.hashrate_total + latestStatMessage.hashrate_unit + '/s' : 'n/a' }}</b></div>
          </div>

          <div class="sysinfoBody">
            <div class="truncateLeft">POOL 01H ......................................................................................................................</div>
            <div class="sysinfoWrapper-right color09"><b>{{ latestStatMessage && latestStatMessage.pool_hashrate_1h ? latestStatMessage.pool_hashrate_1h + latestStatMessage.pool_hashrate_unit + '/s' : 'n/a' }}</b></div>
          </div>

          <div class="sysinfoBody">
            <div class="truncateLeft">POOL 24H ......................................................................................................................</div>
            <div class="sysinfoWrapper-right color09"><b>{{ latestStatMessage && latestStatMessage.pool_hashrate_24h ? latestStatMessage.pool_hashrate_24h + latestStatMessage.pool_hashrate_unit + '/s' : 'n/a' }}</b></div>
          </div>

          <div class="sysinfoHead-right-pro">
            MORE<i class="fas fa-link fas-small fas-right"></i>
          </div>

          <div class="sysinfoBody" style="padding-top:6px; display: block; margin-bottom: -10px;">
            <button type="button" class="btn btn-secondary" style="margin-bottom: 5px;"><i class="fas fa-bezier-curve fas-left"></i><translate>VISIT ANTPOOL.COM</translate></button>
            <button type="button" class="btn btn-secondary" style="margin-bottom: 0px;" @click="gotoConfig()"><i class="fas fa-cog fas-left"></i><translate>CHANGE SETTINGS</translate></button>
          </div>

        </div>

        <!-- DATA 1 - FINANCE -->

        <div key="farmstats" v-else class="container_body sb-right">

          <div class="sysinfoHead-right-pro" style="margin-bottom: 7px;">
            PAYOUTS<i class="fab fa-bitcoin fas-small fas-right"></i>
          </div>

          <a class="genLink" target="_blank" title="Visit." :href="'https://blockstream.info/search?q=' + '12c6DSiU4Rq3P4ZxziKxzrL5LmMBrzjrJX'" style="font-size: 0.8em;">
            <i class="fas fa-wallet fas-small fas-left"></i><b>12c6DSiU4Rq3P4ZxziKx...</b>
          </a>

          <div class="sysinfoBody" style="margin-top: 7px;">
            <div class="truncateLeft">PENDING ......................................................................................................................</div>
            <div class="sysinfoWrapper-right"><b>{{ latestStatMessage && latestStatMessage.pool_shares_accepted ? latestStatMessage.pool_shares_accepted : 'n/a' }}</b></div>
          </div>

          <div class="sysinfoBody">
            <div class="truncateLeft">LAST 24H ......................................................................................................................</div>
            <div class="sysinfoWrapper-right"><b>{{ latestStatMessage && latestStatMessage.pool_shares_invalid ? latestStatMessage.pool_shares_invalid : 'n/a' }}</b></div>
          </div>

          <div class="sysinfoBody">
            <div class="truncateLeft">LAST 30D ......................................................................................................................</div>
            <div class="sysinfoWrapper-right"><b>{{ latestStatMessage && latestStatMessage.pool_shares_stale ? latestStatMessage.pool_shares_stale : 'n/a' }}</b></div>
          </div>

          <div class="sysinfoHead-right-pro">
            30D FORECAST<i class="fas fa-angle-double-right fas-small fas-right"></i>
          </div>

          <div class="sysinfoBody">
            <div class="truncateLeft">REVENUE (&#8383;) ......................................................................................................................</div>
            <div class="sysinfoWrapper-right"><b>{{ latestStatMessage && latestStatMessage.pool_shares_accepted ? latestStatMessage.pool_shares_accepted : 'n/a' }}</b></div>
          </div>

          <div class="sysinfoBody">
            <div class="truncateLeft">REVENUE ({{ farm.currency | currencySymbol }}) ......................................................................................................................</div>
            <div class="sysinfoWrapper-right"><b>{{ latestStatMessage && latestStatMessage.pool_shares_invalid ? latestStatMessage.pool_shares_invalid : 'n/a' }}</b></div>
          </div>

          <div class="sysinfoBody">
            <div class="truncateLeft">PROFIT ({{ farm.currency | currencySymbol }}) ......................................................................................................................</div>
            <div class="sysinfoWrapper-right"><b>{{ latestStatMessage && latestStatMessage.pool_shares_stale ? latestStatMessage.pool_shares_stale : 'n/a' }}</b></div>
          </div>

          <div class="sysinfoHead-right-pro">
            <translate>CONSUMPTION</translate><i class="fas fa-bolt fas-small fas-right"></i>
          </div>

          <div class="sysinfoBody">
            <div class="truncateLeft"><translate>POWERDRAW ({{ getPowerConsumptionUnit() }})</translate> ..................................................................................................................</div>
            <div v-if='latestStatMessage.power_consumption && isOnline' :class="'sysinfoWrapper-left '"><b>{{ getPowerConsumption() }}</b></div>
            <div v-else class="sysinfoWrapper-left"><b>n/a</b></div>
          </div>

          <div class="sysinfoBody">
            <div class="truncateLeft"><translate>EFFICIENCY (J/TH)</translate> ..................................................................................................................</div>
            <div v-if='latestStatMessage.power_consumption && isOnline' :class="'sysinfoWrapper-left '"><b>{{ getEfficiency() }}</b></div>
            <div v-else class="sysinfoWrapper-left"><b>n/a</b></div>
          </div>

          <!--div class="sysinfoBody">
            <div class="truncateLeft"><translate>COST PER kWh ({{ farm.currency | currencySymbol }})</translate> ..................................................................................................................</div>
            <div v-if='farm && farm.price_kwh' :class="'sysinfoWrapper-left '"><b>{{ farm.price_kwh }}</b></div>
            <div v-else class="sysinfoWrapper-left"><b>n/a</b></div>
          </div-->

          <div class="sysinfoBody">
            <div class="truncateLeft"><translate>PROFIT</translate> ({{ farm.currency | currencySymbol }}/{{ getPowerConsumptionUnit() }}h) ..................................................................................................................</div>
            <div v-if='latestStatMessage.power_consumption && isOnline' :class="'sysinfoWrapper-left '"><b>{{ getProfitPerW() }}</b></div>
            <div v-else class="sysinfoWrapper-left"><b>n/a</b></div>
          </div>

          <div class="sysinfoHead-right-pro">
            <translate>EXPANSES</translate><i class="fas fa-wallet fas-small fas-right"></i>
          </div>

          <div class="sysinfoBody">
            <div class="truncateLeft"><translate>COST</translate> ({{ farm.currency | currencySymbol }}/h) ..................................................................................................................</div>
            <div v-if='latestStatMessage.power_consumption && farm.price_kwh' :class="'sysinfoWrapper-left '"><b>{{ getCost('h', farm.currency) }}</b></div>
            <div v-else class="sysinfoWrapper-left"><b>n/a</b></div>
          </div>

          <div class="sysinfoBody">
            <div class="truncateLeft"><translate>COST</translate> ({{ farm.currency | currencySymbol }}/d) ..................................................................................................................</div>
            <div v-if='latestStatMessage.power_consumption && farm.price_kwh' :class="'sysinfoWrapper-left '"><b>{{ getCost('d', farm.currency) }}</b></div>
            <div v-else class="sysinfoWrapper-left"><b>n/a</b></div>
          </div>

          <div class="sysinfoBody" style="margin-bottom: 0px;">
            <div class="truncateLeft"><translate>COST</translate> ({{ farm.currency | currencySymbol }}/m) ..................................................................................................................</div>
            <div v-if='latestStatMessage.power_consumption && farm.price_kwh' :class="'sysinfoWrapper-left '"><b>{{ getCost('m', farm.currency) }}</b></div>
            <div v-else class="sysinfoWrapper-left"><b>n/a</b></div>
          </div>

          <div class="sysinfoBody" style="padding-top:15px; display: block; margin-bottom: -10px;">
            <button type="button" class="btn btn-secondary" style="margin-bottom: 0px;" @click="gotoConfig()"><i class="fas fa-chart-bar fas-left"></i><translate>MORE STATS</translate></button>
          </div>

        </div>

      </transition-group>

    </div>

  </section>

</template>

<script>
import { PRODUCT_MODE } from '@/common/config'

export default {
  name: 'SidebarRight',
  filters: {
    toTH: function (value) {
      if (!value) return ''
      return new Intl.NumberFormat().format(value)
    },
    currencySymbol: function (value) {
      if (value === 'BTC') {
        return '₿'
      } else if (value === 'EUR') {
        return '€'
      } else {
        return '$'
      }
    }
  },
  props: ['farm', 'latestStatMessage'],
  data () {
    return {
      show: true,
      intervalId: null,
      timerRunning: false,
      product_mode: PRODUCT_MODE,
      pool_api: 'antpool',
      timeNow: Math.floor(Date.now() / 1000)
    }
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
  },
  methods: {
    isMobile () {
      if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    fadeSidebars () {
      if (this.timerRunning === false) {
        this.intervalId = setInterval(() => {
          this.timerRunning = true
          if (this.show === false) {
            this.show = true
          } else {
            this.show = false
          }
        }, 8500)
      }
    },
    swapSlides () {
      clearInterval(this.intervalId)
      this.timerRunning = false
      if (this.show === false) {
        this.show = true
      } else {
        this.show = false
      }
      this.fadeSidebars()
    },
    gotoConfig () {
      this.$router.push({ name: 'detail_minerconfig_pro' })
    },
    getEfficiency () {
      if (this.latestStatMessage.hashrate_total > 0) {
        if (this.latestStatMessage.hashrate_unit === 'ZH') {
          return ((this.latestStatMessage.power_consumption / this.latestStatMessage.hashrate_total) / 1000000000).toFixed(0)
        } else if (this.latestStatMessage.hashrate_unit === 'EH') {
          return ((this.latestStatMessage.power_consumption / this.latestStatMessage.hashrate_total) / 1000000).toFixed(0)
        } else if (this.latestStatMessage.hashrate_unit === 'PH') {
          return ((this.latestStatMessage.power_consumption / this.latestStatMessage.hashrate_total) / 1000).toFixed(0)
        } else {
          return (this.latestStatMessage.power_consumption / this.latestStatMessage.hashrate_total).toFixed(0)
        }
      } else {
        return 'n/a'
      }
    },
    getPowerConsumption () {
      if (this.latestStatMessage.power_consumption) {
        if ((this.latestStatMessage.power_consumption / 1000) >= 1000) {
          return (this.latestStatMessage.power_consumption / 1000000).toFixed(1)
        } else {
          return (this.latestStatMessage.power_consumption / 1000).toFixed(1)
        }
      } else {
        return 'n/a'
      }
    },
    getPowerConsumptionUnit () {
      if (this.latestStatMessage.power_consumption) {
        if ((this.latestStatMessage.power_consumption / 1000) >= 1000) {
          return 'MW'
        } else {
          return 'KW'
        }
      } else {
        return 'kW'
      }
    },
    getProfitPerW () {
      return 'n/a'
    },
    getCost (period, value) {
      if (period === 'h') {
        if (value === 'BTC') {
          return ((this.latestStatMessage.power_consumption * this.farm.price_kwh) / 1000).toFixed(8)
        } else {
          return ((this.latestStatMessage.power_consumption * this.farm.price_kwh) / 1000).toFixed(2)
        }
      } else if (period === 'd') {
        if (value === 'BTC') {
          return (((this.latestStatMessage.power_consumption * this.farm.price_kwh) / 1000) * 24).toFixed(8)
        } else {
          return (((this.latestStatMessage.power_consumption * this.farm.price_kwh) / 1000) * 24).toFixed(2)
        }
      } else if (period === 'm') {
        if (value === 'BTC') {
          return (((this.latestStatMessage.power_consumption * this.farm.price_kwh) / 1000) * 24 * 30.5).toFixed(8)
        } else {
          return (((this.latestStatMessage.power_consumption * this.farm.price_kwh) / 1000) * 24 * 30.5).toFixed(2)
        }
      }
    }
  },
  computed: {
    isOnline () {
      return this.latestStatMessage && this.latestStatMessage.miner_count_online > 0 && (this.latestStatMessage.created > (this.timeNow - 180))
    },
    onlinecountIndication () {
      const onlineCount = (this.latestStatMessage ? this.latestStatMessage.miner_count_online : 0)
      const totalCount = (this.latestStatMessage ? this.latestStatMessage.miner_count_total : 0)
      let result = null
      switch (true) {
        case onlineCount === 0:
          result = 'red pulsate'
          break
        case (onlineCount < totalCount):
          result = 'yellow'
          break
        case onlineCount === totalCount:
          result = 'green'
          break
      }
      return result
    },
    hashrateTotal () {
      return this.latestStatMessage.hashrate_total
    },
    hashrateUnit () {
      return this.latestStatMessage.hashrate_unit
    },
    hashratePool01h () {
      return this.latestStatMessage.hashrate_total
    },
    hashratePool12h () {
      return this.latestStatMessage.pool_hashrate_12h
    },
    hashrateUnitPool () {
      return this.latestStatMessage.pool_hashrate_unit
    },
    workercountIndication () {
      const workerCount = (this.latestStatMessage && this.latestStatMessage.pool_worker_count ? this.latestStatMessage.pool_worker_count : 0)
      const totalCount = (this.latestStatMessage ? this.latestStatMessage.miner_count_total : 0)
      let result = null
      switch (true) {
        case workerCount === 0:
          result = 'red pulsate'
          break
        case (workerCount < totalCount):
          result = 'yellow'
          break
        case workerCount === totalCount:
          result = 'green'
          break
      }
      return result
    },
    fanspeedIndication () {
      const fanSpeed = this.latestStatMessage.fanspeed_avg
      let result = null
      switch (true) {
        case fanSpeed >= 85:
          result = 'red'
          break
        case (fanSpeed < 85 && fanSpeed >= 65):
          result = 'fans_01'
          break
        case (fanSpeed < 65 && fanSpeed >= 1):
          result = 'fans_02'
          break
        case fanSpeed < 1:
          result = 'red'
          break
      }
      return result
    },
    temperatureIndication () {
      const temp = this.latestStatMessage.temperature_avg
      let result = null
      switch (true) {
        case temp > 92:
          result = 'red'
          break
        case temp >= 85:
          result = 'temp_02'
          break
        case (temp < 85 && temp >= 65):
          result = 'temp_01'
          break
        case temp < 65:
          result = 'red'
          break
      }
      return result
    },
    poolIndication () {
      const poolStatus = this.latestStatMessage.pool_status
      let result = 'red'
      switch (true) {
        case poolStatus === 0:
          result = 'red'
          break
        case poolStatus === 1:
          result = 'green'
          break
      }
      return result
    }
  },
  mounted () {
    this.fadeSidebars()
  }
}
</script>
