<template>

    <div class="container-fluid">

        <div class="row infoBox">

            <div class="col-md-12">

                <div class="menu fI-slow">

                    <!-- OVERViEW -->

                    <router-link
                      active-class="active"
                      exact
                      :to="{ name: 'overview' }"
                    >
                      <span v-if='!isMobile()' id="menuOverview" class="trans"><i class="fas fa-arrow-circle-left fas-left"></i></span>
                      <span v-else id="menuOverview" class="trans" style="margin-top: -3px; font-size: 1.1em;"><i class="fas fa-arrow-circle-left fas-left"></i></span>
                    </router-link>

                    <span style="color:#3c3c3c!important;cursor:text!important;">&nbsp;\\&nbsp;</span>

                    <!-- DASHBOARD -->

                    <router-link
                      :to="{ name: 'detail', params: { farm_id: farmId } }"
                      exact
                      class="trans"
                      active-class="active"
                    >
                      <span v-if='!isMobile()' id="menuDashboard" class="trans" style="margin-left: 5px; margin-right: 5px;" :title="$gettext('Juicy stats and details.')"><i class="fas fa-chart-bar fas-left"></i><translate style="color:inherit;">DASHBOARD</translate></span>
                      <span v-else id="menuDashboard" class="trans" style="margin-left: 5px; margin-right: 0px; margin-top: -3px; font-size: 1.1em;"><i class="fas fa-chart-bar fas-left"></i></span>
                    </router-link>

                    <span style="color:#3c3c3c!important;cursor:text!important;">&nbsp;\\&nbsp;</span>

                    <!-- MiNER -->

                    <router-link
                      :to="{ name: 'detail_devices', params: { farm_id: farmId } }"
                      exact
                      class="trans"
                      active-class="active"
                    >
                      <span v-if='!isMobile()' id="menuDevices" class="trans" style="margin-left: 5px; margin-right: 5px;" :title="$gettext('Show all miners of the current device-set.')"><i class="fas fa-server fas-left"></i><translate style="color:inherit;">MINERS</translate></span>
                      <span v-else id="menuDevices" class="trans" style="margin-left: 5px; margin-right: 0px; margin-top: -3px; font-size: 1.1em;"><i class="fas fa-server fas-left"></i></span>
                    </router-link>

                    <span style="color:#3c3c3c!important;cursor:text!important;">&nbsp;\\&nbsp;</span>

                    <!-- CONFiGURATiON -->

                    <router-link
                        v-if='product_mode == "pro"'
                        :to="{ name: 'detail_minerconfig_pro', params: { farm_id: farmId } }"
                        exact
                        class="trans"
                        active-class="active"
                        >
                      <span v-if='!isMobile()' id="menuConfiguration" class="trans" style="margin-left: 5px; margin-right: 5px;" :title="$gettext('Miner configuration and setup.')"><i class="fas fa-cogs fas-left"></i><translate style="color:inherit;">CONFIGURATION</translate></span>
                      <span v-else id="menuConfiguration" class="trans" style="margin-left: 5px; margin-right: 0px; margin-top: -3px; font-size: 1.1em;"><i class="fas fa-cogs fas-left"></i></span>
                    </router-link>

                    <span v-if='product_mode == "pro"' style="color:#3c3c3c!important;cursor:text!important;">&nbsp;\\&nbsp;</span>

                    <!-- METRiCS -->

                    <a :href="metrics + '/d/dae5e0ac-32e5-45bb-a738-54b19b7627e9/device-set-detail?var-deviceset_id=' + farmId" target="_blank">
                      <span v-if='!isMobile()' style="margin-left: 5px; margin-right: 5px;" class="trans"><i class="fas fa-chart-bar fas-left"></i><translate style="color:inherit;">SHOW DETAILED METRICS</translate></span>
                      <span v-else style="margin-left: 5px; opacity:0.7; margin-top: -3px; font-size: 1.1em;" class="trans"><i class="fas fa-chart-bar fas-left"></i></span>
                    </a>

                    <span style="color:#3c3c3c!important;cursor:text!important;">&nbsp;\\&nbsp;</span>

                    <!-- KOLLEKTOR -->

                    <span v-if="product_mode === 'cloud'">

                      <span @click="$refs.DeviceImporter.$refs.infoModal.openModal();" v-if='!isMobile()' style="margin-left: 5px; margin-right: 5px;" class="trans"><i class="fas fa-download fas-left"></i><translate style="color:inherit;">CONNECT YOUR ASICs</translate></span>
                      <span @click="$refs.DeviceImporter.$refs.infoModal.openModal();" v-else style="margin-left: 5px; opacity:0.7; margin-top: -3px; font-size: 1.1em;" class="trans"><i class="fas fa-download fas-left"></i></span>

                      <span style="color:#3c3c3c!important;cursor:text!important;">&nbsp;\\&nbsp;</span>

                    </span>

                    <!-- LOGOUT -->

                    <a @click="logout" id="menuLogout" style="margin-left: 5px; opacity:0.7; margin-top: -3px; font-size: 1.1em;" class="trans"><i class="fas fa-sign-out-alt fas-left"></i></a>

                    <!-- APi -->

                    <div v-if='!isMobile()' class="social-wrapper" style="margin:1px 0 0 0; float: right; font-size: 0.95em;">
                      <a :href="api_docs" target="_blank" :title="$gettext('View the API-Documentation')">
                        <span style="margin-left: 5px; margin-right: 5px; color: #424242;" class="trans"><i class="fas fa-code fas-left"></i><translate style="color:inherit;">API</translate></span>
                      </a>
                    </div>

                </div>

            </div>

        </div>

        <DeviceImporter ref="DeviceImporter" v-if="product_mode === 'cloud'"/>

    </div>

</template>

<script>
import { AUTH__LOGOUT } from '@/store/actions.type'
import { PRODUCT_MODE, API_PROTO, API_FQDN, METRICS_PROTO, METRICS_FQDN } from '@/common/config'
import DeviceImporter from '@/components/widgets/DeviceImporterCloud.vue'

export default {
  name: 'logo',
  props: ['username'],
  components: { DeviceImporter },
  computed: {
    farmId () {
      return this.$route.params.farm_id
    }
  },
  data () {
    return {
      product_mode: PRODUCT_MODE,
      api_docs: API_PROTO + '://' + API_FQDN + 'docs',
      metrics: METRICS_PROTO + '://' + METRICS_FQDN
    }
  },
  methods: {
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    logout () {
      this.$store.dispatch(AUTH__LOGOUT).then(() => {
        this.$router.push({ name: 'login' })
      })
    }
  }
}
</script>
